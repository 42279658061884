const StringTableEN = {
  welcome: "Welcome!",
  home: "Home",
  services: "Products",
  history: "History",
  sharing: "Sharing",
  consults: "Consult",
  concept: "Concept",
  labs: "RevLabs",

  "consults-mobile": "Consults",
  "labs-mobile": "RevLabs",

  about: "About",
  project: "Experience",
  product: "Services",
  industry: "Tech",
  "history-mobile": "History",
  "services-mobile": "Products",
  "about-mobile": "About",
  "project-mobile": "Experience",
  "product-mobile": "Services",
  "industry-mobile": "Technology",
  "sharing-mobile": "Sharing",
  "landing-title": "WEB x APP x IOT x\nBLOCKCHAIN",
  slogan: "The last mile to digital integration from online to offline",
  "landing-title_formula": "From Concentration",
  "landing-title_answer": "To High Quality",
  "landing-introduction":
    "Tailor-made, nothing missing.\nRevtel Tech is committed to providing the highest quality software services.",
  "landing-description": "",
  "landing-subtitle-1": "Experience",
  "landing-subtitle-2": "Satisfaction",
  "landing-subtitle-3": "Projects",
  "landing-core-value_title": "Core Value",
  "landing-core-value_revolutionary_description": "",
  "landing-core-value_revenue_description": "",
  "landing-core-value_revive_description": "",
  "landing-our-partners_title": "Our Partners",
  "landing-our-partners_introduction":
    "Our partners span more than 10 industries. We continue to optimize our technology to make our services even better and more convenient.-en",
  "see-more-industry": "More",
  "history-title": "",
  "history-introduction": "",
  "tag-all": "ALL",
  "tag-works": "WORKS",
  "tag-report": "NEWS",
  "tag-open-source": "OPEN SOURCE",
  "tag-sharing": "SHARING",
  "about-title": "",
  "about-introduction": "",
  "about-core-technology_title": "",
  "about-core-technology_introduction": "",
  "about-contact_title": "Contact",
  "about-contact_tel": `(02)2557-8895`,
  "about-contact_email": `contact@revteltech.com`,
  "about-contact_address": `10F.-5, No. 136, Minquan W. Rd., Datong Dist., Taipei City 103, Taiwan`,
  "about-office_introduction":
    'Revtel Tech was established in 2016, adopts Scrum agile development, and is committed to software services such as web pages, apps, the Internet of Things, and technical consulting. It also has extensive experience in Bluetooth and other hardware communications. The main products are "Tessuto Travel Staying Butler", "Travelbot B2B Travel Online Secretary Platform" and "Meeting Event Tracking Management System".-en',
  "about-team_title": "Our Team",
  "project-title": "Projects",
  "project-introduction":
    "Revtel Tech served over 80 software projects throughout 10 different business domains (actual number still rising). From IPO companies to startups, we assist our clients to achieve business milestones. Website only shows partially of our references, if you would like to know more, please contact us!",
  "product-title": "Products",
  "product-description":
    "Software technology is a complicated art in the real world. To describe a correspondent use case, contains the strategy in various condition in business spectrum.  Revtel Tech provide full service that assist client to build up positive and long-term mindset at the beginning, including risking management, product developing cycle and operations.  Module Solution: We also provide module solutions including App notification, membership system and file storage system.",
  "product-service-step1_title": "Consultant",
  "product-service-step1_from": "Business Goal",
  "product-service-step1_to": "Excution Plan",
  "product-service-step1_description":
    "Information Technology is now universally apply to our daily life, by rapid evolution to mobile application and cloud services, we believe that technology in software not only drives strategy to industries but taking important place to make technical decisions. Our value is to pursue business partner optimized advantatge from our professional knowledge and skllls by establishin g strong and long-term competitve development.-en",
  "product-service-step2_title": "Development",
  "product-service-step2_from": "Execution Plan",
  "product-service-step2_to": "Real Products",
  "product-service-step2_description":
    "Revtel Tech is specialized in mobile web, cloud computing, IoT, mobile solutions both iOS and Android, AI and blockchain. Technologies are all around and keep updating, through our services, We are looking forwardo to amplify your business.-en",
  "product-service-step3_title": "Collaboration",
  "product-service-step3_from": "Real Products",
  "product-service-step3_to": "Business Success",
  "product-service-step3_description":
    "Unlike traditional product developing, modern software product requires continous enhancements to fit dynamic changes in the market, we committed to deliver agile and strong collaboraton to our clients and dedicate ourselves in leading position to software technology segment.-en",
  "industry-title": "Technology",
  "tech-good": "Tech List",
  "industry-description":
    "Since mobile technology goes overwhelming in the market, there is great shifting among business and also as each domain. The combination and integration are major challenge to precisely adapt to business owner. As a role of software leading company, from website to application, IoT to blockchain, we are confident and excited to upgrade your business.",
  "industry-content_title_1": "Professional IT solutions",
  "industry-content_title_2": "Customized Your Advantage",
  "industry-item_title_medical": "Medical",
  "industry-item_title_finance": "Finance",
  "industry-item_title_blockchain": "BlockChain",
  "industry-item_title_education": "Education",
  "industry-item_title_ecommerce": "E-Commerce",
  "industry-item_title_fitness": "Fitness",
  "industry-item_title_website": "Official Website",
  "industry-item_title_logistic": "Logistic",
  "industry-item_title_travel": "Hospitality",
  "industry-item_title_art": "CultureArt",
  "industry-item_title_postpartum": "NursingCenter",
  "industry-item_title_tranditindustry": "Manufacturer",
  "industry-item_title_npo": "NPO",
  "contact-title": "Contact",
  "contact-description":
    "Please send us you requirements or ideas!\nWe will contact your via email or phone, thank you!",
  "contact-field-name": "Name",
  "contact-field-firm": "Company Name",
  "contact-field-email": "Email",
  "contact-field-phone": "Phone",
  "contact-field-type": "Project Type",
  "contact-field-type-option-app": "APP Development",
  "contact-field-type-option-web": "WEB  Development",
  "contact-field-type-option-ecommerrce": "EC Development",
  "contact-field-type-option-system": "System Development",
  "contact-field-type-option-other": "Others",
  "contact-field-requirement": "Additional information",
  "contact-send": "SEND",
  "contact-hint":
    "One of our team members will get back to you right away about your enquiry.",
  "contact-us": "Contact",
  "see-more": "See More",
  "read-more": "Read More",
};
export default {
  display: "English",
  key: "en",
  stringTable: StringTableEN,
};
