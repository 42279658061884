import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import { SupportLangs } from "../StringTable";
import * as Icon from "../Components/Icon";

class LanguageSettingIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    // variable for mouse click to identify whether the event is happend outside the menu
    this._isClickOutside = true;
  }

  componentDidMount() {
    try {
      // prevent Reference Error: document in server side
      // true -> listener get the event first, false -> onClick attach get the event first
      document.addEventListener("click", this._mouseClick, false);
    } catch (err) {
      //
    }
  }

  componentWillUnmount() {
    try {
      document.removeEventListener("click", this._mouseClick, false);
    } catch (err) {
      //
    }
  }

  render() {
    if (this.props.simpleMode) {
      return this._renderSimpleMode();
    } else {
      return this._renderDropDownMode();
    }
  }

  _renderSimpleMode() {
    return (
      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          ...(this.props.style || {}),
        }}
      >
        <div
          style={{
            ...(this.props.lang === "tw"
              ? { color: "black", backgroundColor: "#eeeeee" }
              : { color: "lightgrey", backgroundColor: "white" }),
            borderRadius: 10,
            padding: 10,
          }}
          onClick={e => {
            this._isClickOutside = false;
            this.setState({ isOpen: false }, () =>
              this.props.appActions.setLang("tw")
            );
            // FIXME : force change url since we detect lang para in Layout.js
            // FIXME : only consider tw / en two case
            window.location.href = window.location.href.replace("/en", "");
          }}
        >
          正體中文
        </div>
        <div style={{ width: 50 }}></div>
        <div
          style={{
            ...(this.props.lang === "en"
              ? { color: "black", backgroundColor: "#eeeeee" }
              : { color: "lightgrey", backgroundColor: "white" }),
            borderRadius: 10,
            padding: 10,
          }}
          onClick={e => {
            this._isClickOutside = false;
            this.setState({ isOpen: false }, () =>
              this.props.appActions.setLang("en")
            );
            // FIXME : force change url since we detect lang para in Layout.js
            // FIXME : only consider tw / en two case

            if (window.location.href.indexOf("/en") === -1) {
              window.location.href =
                window.location.origin + "/en" + window.location.pathname;
            }
          }}
        >
          English
        </div>
      </div>
    );
  }

  _renderDropDownMode() {
    let {
      appActions,
      css = "",
      lang: selectedLang,
      menuOffset = 0,
    } = this.props;
    let { isOpen } = this.state;
    return (
      <Wrapper css={css} isOpen={isOpen} menuOffset={menuOffset}>
        <div
          className="button-wrapper"
          onClick={() => {
            this._isClickOutside = false;
            this.setState({ isOpen: !isOpen });
          }}
        >
          <Icon.Language size={30} color="#F26D28" />
        </div>
        <div className="toggle-menu">
          {SupportLangs.map(lang => (
            <div
              className={
                "menu-item" + (lang.key === selectedLang ? " active" : "")
              }
              key={lang.key}
              onClick={e => {
                this._isClickOutside = false;
                this.setState({ isOpen: false }, () =>
                  appActions.setLang(lang.key)
                );
                // FIXME : force change url since we detect lang para in Layout.js
                // FIXME : only consider tw / en two case
                if (lang.key === "tw") {
                  window.location.href = window.location.href.replace(
                    "/en",
                    ""
                  );
                } else {
                  if (window.location.href.indexOf("/en") === -1) {
                    window.location.href =
                      window.location.origin + "/en" + window.location.pathname;
                  }
                }
              }}
            >
              <div className="bullet-point" />
              {lang.display}
            </div>
          ))}
        </div>
      </Wrapper>
    );
  }

  _mouseClick = e => {
    if (!this._isClickOutside) {
      this._isClickOutside = true;
      return;
    }
    this.setState({ isOpen: false });
  };
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  & .button-wrapper {
    cursor: pointer;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .toggle-menu {
    position: absolute;
    background-color: white;

    padding: 20px 0px;
    width: 150px;
    border-bottom: 1px solid #f1f1f1;
    box-shadow: -2px -2px 7px 1px rgba(132, 132, 132, 0.06);
    /* animation */
    transition: all 0.2s;
    pointer-events: ${props => (props.isOpen ? "all" : "none")};
    opacity: ${props => (props.isOpen ? 1 : 0)};
    top: ${props =>
      props.isOpen ? "calc(100% + " + props.menuOffset + "px )" : "20%"};

    & .menu-item {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: #4e4e4e;
      text-transform: uppercase;
      cursor: pointer;
      padding: 4px 20px;
      display: flex;
      align-items: center;
      background-color: #fff;
      transition: background-color 0.3s;

      &:hover {
        background-color: rgba(200, 200, 200, 0.5);
      }

      & .bullet-point {
        width: 7px;
        height: 7px;
        background-color: #4e4e4e;
        margin-right: 8px;
      }
    }

    & .menu-item.active {
      color: #f36a26;

      & .bullet-point {
        background-color: #f36a26;
      }
    }
  }
  ${props => props.css}
`;

export default connect(
  (state, ownProps) => ({
    lang: Selectors.getLang(state),
  }),
  ActionCreator
)(LanguageSettingIcon);
