import Actions from "./Actions";

const initialState = {
  isContactFormOpen: false,
};
function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case Actions.TOGGLE_CONTACT_US_FORM:
      return {
        ...state,
        isContactFormOpen: !state.isContactFormOpen,
      };

    default:
      return state;
  }
}

export default reducer;

export function getContactFormState(state) {
  return state.isContactFormOpen;
}
