import { navigate } from "gatsby";
import Actions from "./Reducers/Actions";
import { getStore } from "./Store";
import Catchify from "./Utils/Catchify";
import Api from "./Api";
import signinActionsFactory from "rev-cms-core/dist/signin/ActionCreator";
import AsyncStorage from "rev-cms-core/dist/common/AsyncStorage";
import constants from "./constants";

const signinActions = signinActionsFactory({
  apiUrl: constants.apiUrl,
  AsyncStorage,
})(getStore());

export default dispatch => ({
  navActions: {
    push: Catchify(async (url, param) => {
      if (window) {
        console.log("window = ", window);
      }

      if (!param) {
        navigate(`/${url}`);
      } else {
        navigate(`/${url}`, param);
      }
    }),
  },

  appActions: {
    ...signinActions, // login, logout

    setLang: Catchify(async lang => {
      dispatch({ type: Actions.SET_LANG, payload: lang });
    }),

    toggleContactForm: () => {
      dispatch({ type: Actions.TOGGLE_CONTACT_US_FORM, payload: null });
    },

    sendEmail: async data => {
      try {
        // html (boolean), subject, message, to
        let resp = await Api.sendEmail(data);
        return resp;
      } catch (err) {
        console.warn(err);
        return Promise.reject(err);
      }
    },

    getArticles: async () => {
      try {
        let resp = await Api.getArticles();
        return resp;
      } catch (err) {
        console.warn(err);
        return Promise.reject(err);
      }
    },

    getArticleById: async id => {
      try {
        let resp = await Api.getArticleById(id);
        return resp;
      } catch (err) {
        console.warn(err);
        return Promise.reject(err);
      }
    },

    echo: async () => {
      return await Api.echo("hello");
    },
  },
});
