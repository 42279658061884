const ApiProxyFactory = require("./ApiProxy");
const constants = require("./constants");

const ApiProxy = new ApiProxyFactory({ apiUrl: constants.apiUrl });
const SendEmailApiKey = "69646a115ae647389e1cb061e7a95db0"; // email sent by: contact@revteltech.com
const ArticleApiHost = constants.revBlogServiceApi;

module.exports = {
  getToken: () => {
    return ApiProxy.token;
  },

  setToken: token => {
    ApiProxy.setToken(token);
  },

  sendEmail: data => {
    ApiProxy.post({
      path: "https://mail.revtel-api.com/api/send_mail/",
      withHost: true,
      secure: false,
      data: {
        ...data,
        api_key: SendEmailApiKey,
      },
    });
  },

  getArticles: async () => {
    return ApiProxy.get({
      path: `${ArticleApiHost}/articles?project=revtel-official-website&label=history&flag=publish`,
      withHost: true,
      secure: false,
    });
  },

  getArticleById: async id => {
    return ApiProxy.get({
      path: `${ArticleApiHost}/article/${id}`,
      withHost: true,
      secure: false,
    });
  },

  echo: async msg => {
    return msg;
  },
};
