import styled from "styled-components";
import {
  HeaderFontCss,
  ParagraphFontCss,
  ButtonFontCss,
} from "../Utils/CssStyleTemplateUtil";

const Row = styled.div`
  display: flex;
  align-items: ${props => props.align || "stretch"};
  justify-content: ${props => props.justify || "flex-start"};
  flex-wrap: ${props => (props.wrap ? "wrap" : "nowrap")};
  ${props => props.css || ""};
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align || "stretch"};
  justify-content: ${props => props.justify || "flex-start"};
  ${props => props.css};
`;

const Spinner = styled.div`
  width: 45px;
  height: 45px;

  border-radius: 50%;
  border: 4px solid lightgray;
  border-top: 4px solid white;
  animation: spin 1s ease-in-out infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const Button = styled.button`
  /* reset default button style */
  border: none;
  &:hover,
  &:focus {
    outline: none;
    border: none;
  }
  width: 100%;
  cursor: pointer;
  padding: 12px 20px;
  color: white;
  background-color: #f36a26;
  font-size: 20px;
  ${ButtonFontCss}
  align-self: flex-start;
  ${props => props.css || ""};
`;

const LinearGradientButton = styled.a`
  position: relative;
  background-image: linear-gradient(64deg, #faa34e 1%, #f36a26 88%);
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
  border-radius: 17px;
  max-width: 110px;
  width: 100%;
  padding: 7px 24px;

  cursor: pointer;
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  color: #ffffff;
  letter-spacing: 0.41px;
  ${ButtonFontCss}

  &:active {
    top: 1px;
    left: 1px;
  }

  ${props => props.css || ""};
`;

export { Row, Col, Spinner, Button, LinearGradientButton };
