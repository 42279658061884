import StringTableTW from "./StringTable.tw";
import StringTableEN from "./StringTable.en";

export const SupportLangs = [StringTableTW, StringTableEN];

export const DefaultLang = SupportLangs[0].key;

let StringTable = SupportLangs.reduce((acc, { key, stringTable }) => {
  return {
    ...acc,
    [key]: stringTable,
  };
}, {});

export default StringTable;
