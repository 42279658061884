import React, { Component } from "react";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import styled from "styled-components";
import Selectors from "../Selectors";
import * as Icon from "./Icon";
import { HeaderFontCss } from "../Utils/CssStyleTemplateUtil";
import { s } from "../Utils/Lang";
import Selector from "./Selector";
import EmailTemplate from "../Utils/ContactFormEmailTemplate";

const FormFields = [
  { placeholder: s("contact-field-name"), key: "name", type: "input" },
  { placeholder: s("contact-field-firm"), key: "firm", type: "input" },
  { placeholder: s("contact-field-email"), key: "email", type: "input" },
  { placeholder: s("contact-field-phone"), key: "phone", type: "input" },
  {
    placeholder: s("contact-field-type"),
    key: "type",
    type: "select",
    options: [
      {
        value: s("contact-field-type-option-app"),
        display: s("contact-field-type-option-app"),
      },
      {
        value: s("contact-field-type-option-web"),
        display: s("contact-field-type-option-web"),
      },
      {
        value: s("contact-field-type-option-ecommerrce"),
        display: s("contact-field-type-option-ecommerrce"),
      },
      {
        value: s("contact-field-type-option-system"),
        display: s("contact-field-type-option-system"),
      },
      {
        value: s("contact-field-type-option-other"),
        display: s("contact-field-type-option-other"),
      },
    ],
  },
  {
    placeholder: s("contact-field-requirement"),
    key: "requirement",
    type: "textarea",
  },
];
class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      error: "",
      success: "",
      emptyFields: [],
    };
  }
  render() {
    let { isOpen, appActions } = this.props;
    let { formData, error, success, emptyFields } = this.state;

    return (
      <Wrapper isOpen={isOpen}>
        <div className="close-btn" onClick={appActions.toggleContactForm}>
          <Icon.Close size={30} color={"#4E4E4E"} />
        </div>
        <div
          style={{
            width: "100%",
            padding: 50,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="form-container">
            <div
              className="form-title"
              style={{ color: "#F26D27", fontSize: 28 }}
            >
              {s("contact-title")}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "80%",
            }}
          >
            {[
              [
                "/images/email.png",
                "contact@revteltech.com",
                "mailto:contact@revteltech.com",
              ],
              ["/images/phone-call.png", "(02)2557-8895", "tel:+886225578895"],
              [
                "/images/icon-facebook.png",
                "RevtelTech",
                "https://www.facebook.com/RevtelTech/",
              ],
              [
                "/images/icon-line.png",
                "RevtelTech",
                "https://lin.ee/Qg2AUocY",
              ],
            ].map(item => {
              return (
                <a
                  href={item[2]}
                  target="_blank"
                  key={item[0]}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    textDecoration: "none",
                    marginTop: 20,
                    width: "100%",
                    color: "grey",
                  }}
                  alt={item[1]}
                >
                  <img src={item[0]} style={{ width: 32, height: 32 }} />
                  &nbsp;&nbsp;&nbsp;
                  <p style={{}}>{item[1]}</p>
                </a>
              );
            })}
            <img
              src="/images/revtel-logo-02.png"
              style={{ width: "100%", marginTop: 50 }}
            />
          </div>
        </div>
      </Wrapper>
    );

    return (
      <Wrapper isOpen={isOpen}>
        <div className="close-btn" onClick={appActions.toggleContactForm}>
          <Icon.Close size={30} color={"#4E4E4E"} />
        </div>
        <div className="form-wrapper">
          <div className="form-container">
            <div className="form-title">{s("contact-title")}</div>
            <div className="form-description">{s("contact-description")}</div>
            {FormFields.map((field, idx) => {
              const _onFieldChange = (field, e) => {
                this.setState({
                  formData: {
                    ...formData,
                    [field.key]: e.target.value,
                  },
                  error: "",
                  success: "",
                });
              };
              let commonAttrs = {
                key: idx,
                placeholder: s("contact-field-" + field.key),
                onChange: _onFieldChange.bind(null, field),
                value: formData[field.key] || "",
              };
              switch (field.type) {
                case "textarea":
                  return (
                    <div className="textarea-container" key={"d" + idx}>
                      <textarea {...commonAttrs} key={idx} />
                      {emptyFields.indexOf(field.key) >= 0 && (
                        <p className="hint">* MUST *</p>
                      )}
                    </div>
                  );
                case "select":
                  return (
                    <div className="select-container" key={"d" + idx}>
                      <Selector
                        {...commonAttrs}
                        options={field.options}
                        css={""}
                      />
                    </div>
                  );
                case "input":
                default:
                  return (
                    <div className="input-container" key={"d" + idx}>
                      <input {...commonAttrs} key={idx} />
                      {emptyFields.indexOf(field.key) >= 0 && (
                        <p className="hint">* MUST *</p>
                      )}
                    </div>
                  );
              }
            })}
            <div
              className={"send-btn"}
              onClick={() =>
                this._checkFieldsEmpty(FormFields)
                  ? this.setState({
                      error:
                        "There are still required items to be filled out !",
                    })
                  : this._sendEmail()
              }
            >
              {s("contact-send")}
            </div>

            <div className={"msg-container"}>
              {success && (
                <div className={"success-msg"}>
                  <Icon.SentimentSatisfiedAlt
                    size={18}
                    color={"#35b53a"}
                    css={"margin-right: 3px;"}
                  />
                  {success}
                </div>
              )}
              {error && (
                <div className={"error-msg"}>
                  <Icon.ErrorOutline
                    size={18}
                    color={"tomato"}
                    css={"margin-right: 3px;"}
                  />
                  {error}
                </div>
              )}
              <div className={"hint-msg"}>{s("contact-hint")}</div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  _checkFieldsEmpty = () => {
    let { formData } = this.state;
    let requiredField = FormFields.map((field, idx) => field.key);
    let filledField = Object.keys(formData);

    let emptyFields = [];
    requiredField.map(f => {
      if (filledField.indexOf(f) < 0 || formData[f] == "") {
        emptyFields.push(f);
      }
    });

    this.setState({ formData: { ...this.state.formData }, emptyFields });
    if (emptyFields.length > 1) {
      return true;
    } else {
      return false;
    }
  };

  _sendEmail = async () => {
    let { appActions } = this.props;
    let { formData } = this.state;
    let emailContent = EmailTemplate({
      message: "官網有新訊息！",
      formData,
      title: `${formData.type}`,
      css: "",
    });
    try {
      let resp = await appActions.sendEmail({
        html: true,
        subject: `官網來信：${formData.type}`,
        message: emailContent,
        to: "contact@revteltech.com",
      });
      this.setState({
        formData: {},
        success: "已收到您的表單！",
      });
    } catch (err) {
      this.setState({
        error: "發生錯誤未能收到您的表單，請電話聯絡我們！",
      });
    }
  };
}

const Wrapper = styled.div`
  position: fixed;
  height: 100vh;
  z-index: 20;

  --base-width: calc((100vw - 360px - 70px) / 2);
  /* contact form width will align with project page's project item width */
  width: var(--base-width);
  @media screen and (max-width: 900px) {
    --base-width: calc((100vw - 70px) / 2);
  }
  @media screen and (max-width: 500px) {
    --base-width: 100vw;
  }

  min-width: 300px;
  max-width: 100vw;
  background-color: white;
  border: 1px solid #f1f1f1;
  right: 0px;
  display: flex;
  justify-content: center;

  /* - 70px is for some case min-width contraint the width, so we translate x for it */
  ${props =>
    props.isOpen
      ? `right: 0px;`
      : `right: calc(-1 * var(--base-width)); transform: translate(70px, 0px);`}
  transition: right .2s;

  .close-btn {
    position: absolute;
    left: 0px;
    top: 0px;
    padding: 15px;
    cursor: pointer;
  }

  .form-wrapper {
    padding: 60px 100px 70px;
    overflow: auto;

    @media screen and (max-width: 1024px) {
      padding: 60px 35px 70px;
    }

    .form-container {
      .form-title {
        font-size: 26px;
        line-height: 35px;
        letter-spacing: 0.95px;
        color: #f36a26;
        ${HeaderFontCss}
        margin-bottom: 8px;
      }

      .form-description {
        white-space: pre-wrap;
        line-height: 18px;
        font-size: 14px;
        color: #4e4e4e;
        letter-spacing: 0.4px;
        margin-bottom: 40px;
      }

      .msg-container {
        margin-top: 40px;
      }

      .error-msg {
        margin-bottom: 6px;
        font-size: 14px;
        color: tomato;
      }

      .success-msg {
        margin-bottom: 6px;
        font-size: 14px;
        color: #35b53a;
      }

      .hint-msg {
        font-size: 14px;
        color: #4e4e4e;
        letter-spacing: 0.4px;
        line-height: 18px;
      }

      .send-btn {
        background-image: linear-gradient(41deg, #faa34e 0%, #f36a26 95%);
        color: white;
        font-size: 14px;
        ${HeaderFontCss}
        letter-spacing: 0.4px;
        width: 110px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .input-container {
        display: flex;
        border: 1px solid #4e4e4e;
        padding: 10px;
        height: 46px;
        width: 100%;
        margin-bottom: 20px;
      }

      input {
        border: 0px;
        color: #444;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.4px;
        ${HeaderFontCss}

        &::placeholder {
          color: #a6a6a6;
          text-transform: capitalize;
        }
        &:focus {
          box-shadow: none;
          outline: none;
        }
      }

      .textarea-container {
        display: flex;
        border: 1px solid #4e4e4e;
        padding: 10px;
        height: 90px;
        width: 100%;
        margin-bottom: 30px;
      }

      textarea {
        border: 0px;
        color: #444;
        font-size: 14px;
        line-height: 25px;
        letter-spacing: 0.4px;
        resize: none;
        ${HeaderFontCss}

        &::placeholder {
          color: #a6a6a6;
          text-transform: capitalize;
        }
        &:focus {
          box-shadow: none;
          outline: none;
        }
      }

      .select-container {
        height: 46px;
        width: 100%;
        border: 1px solid #4e4e4e;
        margin-bottom: 20px;
      }

      .hint {
        color: #f36a26;
        font-size: 14px;
        min-width: 64px;
      }
    }
  }
`;

export default connect(
  (state, ownProps) => ({
    isOpen: Selectors.getContactFormState(state),
  }),
  ActionCreator
)(ContactForm);
