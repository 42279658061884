const Settings = {
    env: "prod",
    webUrl: "https://www.revteltech.com",
    apiUrl: "http://localhost:8000",
    mediaHost: "https://dvkvars7ubgms.cloudfront.net",
    s3Host: "https://s3-ap-northeast-1.amazonaws.com/stg.hecafe.com.tw",
    debug: false,
    facebookAppId: "1276459929127208",
    googleClientId: "115128487422-bs5m8q90u3g4ata8lb9ujorusgpqhefj.apps.googleusercontent.com",
    googleAnalyticsId: "UA-165938809-1",
    userType: "aws",
    gtmId: 'GTM-W6BRQ8S',
    ga4Id: 'G-P1X5NBD4EF',
    slsApi: {
      host: "http://localhost:3000",
      bigTableHost: "http://localhost:3000",
    },
    revBlogServiceApi: "https://blog.revtel-api.com"
};

module.exports = Settings;

