import React, { Component } from "react";
import styled from "styled-components";
import * as Icon from "./Icon";
import {
  HeaderFontCss,
  ParagraphFontCss,
  ButtonFontCss,
} from "../Utils/CssStyleTemplateUtil";

// @TODO: will be better to extract Selector as a component
class Selector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    // variable for mouse click to identify whether the event is happend outside the menu
    this._isClickOutside = true;
  }

  componentDidMount() {
    try {
      // prevent Reference Error: document in server side
      // true -> listener get the event first, false -> onClick attach get the event first
      document.addEventListener("click", this._mouseClick, false);
    } catch (err) {
      //
    }
  }

  componentWillUnmount() {
    try {
      document.removeEventListener("click", this._mouseClick, false);
    } catch (err) {
      //
    }
  }

  render() {
    let {
      placeholder = "",
      options = [],
      value = "",
      onChange = () => 0,
      css = "",
    } = this.props;
    let { isOpen } = this.state;
    return (
      <Wrapper css={css} isOpen={isOpen}>
        <div
          className="button-wrapper"
          onClick={() => {
            this._isClickOutside = false;
            this.setState({ isOpen: !isOpen });
          }}
        >
          {value && options.find(o => o.value === value) ? (
            <div className="display">
              {options.find(o => o.value === value).value}
            </div>
          ) : (
            <div className="placeholder">{placeholder}</div>
          )}

          <div className="drop-icon">
            <Icon.KeyboardArrowDown size={20} color={"#4E4E4E"} />
          </div>
        </div>
        <div className="toggle-menu">
          {options.map(o => {
            let isActive = o.value === value;
            return (
              <div
                className={"menu-item" + (isActive ? " active" : "")}
                key={o.value}
                onClick={e => {
                  this._isClickOutside = false;
                  this.setState({ isOpen: false }, () => {
                    onChange({ target: { value: o.value } });
                  });
                }}
              >
                <Icon.Check
                  color={isActive ? "#f36a26" : "white"}
                  size={20}
                  css={"margin-right: 8px;"}
                />
                {o.display}
              </div>
            );
          })}
        </div>
      </Wrapper>
    );
  }

  _mouseClick = e => {
    if (!this._isClickOutside) {
      this._isClickOutside = true;
      return;
    }
    this.setState({ isOpen: false });
  };
}

const Wrapper = styled.div`
  position: relative;
  z-index: 10; /* this seems to cause click event trigger priority... */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  & .button-wrapper {
    cursor: pointer;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    font-size: 14px;
    ${HeaderFontCss}
    .placeholder {
      color: #a6a6a6;
    }
    .display {
      color: #444;
    }
    .drop-icon {
    }
  }

  & .toggle-menu {
    position: absolute;
    background-color: white;
    right: 0px;
    padding: 10px 0px;
    width: 100%;
    border: 1px solid lightgrey;
    border-top: none;
    box-shadow: 0px 4px 7px 1px rgba(132, 132, 132, 0.39);
    /* animation */
    transition: all 0.2s;
    pointer-events: ${props => (props.isOpen ? "all" : "none")};
    opacity: ${props => (props.isOpen ? 1 : 0)};
    top: ${props => (props.isOpen ? "100%" : "20%")};

    & .menu-item {
      height: 35px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: #4e4e4e;
      text-transform: uppercase;
      cursor: pointer;
      padding: 12px 20px;
      display: flex;
      align-items: center;
      background-color: #fff;
      transition: background-color 0.3s;

      &:hover {
        background-color: rgba(200, 200, 200, 0.5);
      }
    }

    & .menu-item.active {
      color: #f36a26;
    }
  }
  ${props => props.css}
`;

export default Selector;
