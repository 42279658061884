import * as _User from "./Reducers/User";
import * as _Lang from "./Reducers/Lang";
import * as _AppState from "./Reducers/AppState";
import SiginSelectors from "rev-cms-core/dist/signin/Selectors";
import { namedExportWithMemorizedOnce } from "./Utils/FpUtil";
import ParseQuery from "./Utils/ParseQuery";

let User = namedExportWithMemorizedOnce(_User);
let Lang = namedExportWithMemorizedOnce(_Lang);
let AppState = namedExportWithMemorizedOnce(_AppState);

const selectors = {
  getQueryParams: ownProps => ParseQuery(ownProps.location.search),
  getLang: state => Lang.getLang(state.lang),
  getContactFormState: state => AppState.getContactFormState(state.appState),
  getLoginUser: state => User.getLoginUser(state.user),
  ...SiginSelectors,
};

export default selectors;
