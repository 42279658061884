export const HeaderFontCss = `font-family: "Noto Sans TC", sans-serif;`;

export const ParagraphFontCss = `font-family: "Noto Sans TC", sans-serif; font-weight: 300;`;

export const ButtonFontCss = `font-family: "Barlow Condensed", sans-serif;`;

export const DateFontCss = `font-family: "Barlow Semi Condensed", sans-serif;`;

export const LabelFontCss = `font-family: "Barlow Condensed", sans-serif;`;

export const HintFontCss = `font-family: "Barlow Semi Condensed", sans-serif;`;

export const TitleFontCssByEN = `font-family: "Barlow Condensed", sans-serif;`;
